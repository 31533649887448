import { template as template_50a14f52fc2d4a648f4a216e3bf247f7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_50a14f52fc2d4a648f4a216e3bf247f7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
