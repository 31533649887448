import { template as template_cd834c1303284e99bf8857a27639ac97 } from "@ember/template-compiler";
const FKLabel = template_cd834c1303284e99bf8857a27639ac97(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
