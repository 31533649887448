import { template as template_86f2e4ced5a34754b26b6ac835575b8d } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_86f2e4ced5a34754b26b6ac835575b8d(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
