import { template as template_ccec347b8bbe46a88798da1a84783e35 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ccec347b8bbe46a88798da1a84783e35(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
